<template>
  <div class="content-box fit-content">
    <div class="inner register-inner">
      <div class="top-box">
        <h3 class="title">{{ stepTitle }}</h3>
        <p>{{ $t(`verificationStatus.dueToAML`) }}</p>
      </div>
      <div class="step-card-box">
        <div class="step-card" v-for="(card, key, index) in cardData" :key="index">
          <h4 class="sub-title">{{ card.title }}</h4>
          <p class="step-card-content">{{ card.content }}</p>
          <Button
            height="unset"
            width="168"
            :buttonType="card.buttonType"
            :customClasses="['card-btn']"
            :disabled="card.buttonType.includes('disabled')"
            @click="goToRegister(key)"
          >
            <div>{{ $t(`verificationStatus.kyc.${card.states}`) }}</div>
          </Button>
          <div class="step-card-link" v-if="showDepositNow(card.states, key)" @click="goToDeposit">
            {{ $t('verificationStatus.kyc.depositNow') }}
            <img src="@/assets/images/register/arrowRight.png" alt />
          </div>

          <div class="step-card-link" v-if="card.states === 'complete' && key === '3'" @click="goToWithdraw">
            {{ $t('verificationStatus.kyc.withdrawNow') }}
            <img src="@/assets/images/register/arrowRight.png" alt />
          </div>

          <div class="step-card-note" v-if="card.states.includes('resubmit')">
            {{ $t('verificationStatus.resubmitNote') }}
          </div>

          <i18n
            path="verificationStatus.rejectedNote"
            tag="div"
            class="step-card-note"
            v-if="card.states.includes('rejected')"
          >
            <template v-slot:liveChat>
              <span @click="showLiveChat">{{ $t('verificationStatus.liveChat') }}</span>
            </template>
          </i18n>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/components/common/Button.vue';
import { REGISTERROUTER, DEPOSIT_PATHNAME } from '@/constants/route';
import { statusConfig } from '@/constants/status';
import { getStatus } from '@/util/register';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'Step',
  components: { Button },
  data() {
    return {
      cardData: {
        1: {
          title: this.$t('verificationStatus.accOpeningVerification.title'),
          content: this.$t('verificationStatus.accOpeningVerification.content'),
          states: 'createNow',
          buttonType: 'disabled'
        },
        2: {
          title: this.$t('verificationStatus.identityVerification.title'),
          content: this.$t('verificationStatus.identityVerification.content'),
          states: 'start',
          buttonType: 'disabled'
        },
        3: {
          title: this.$t('verificationStatus.poaVerification.title'),
          content: this.$t('verificationStatus.poaVerification.content'),
          states: 'start',
          buttonType: 'disabled'
        }
      }
    };
  },
  computed: {
    ...mapState('register', ['currStep']),
    stepTitle() {
      switch (this.activeNum) {
        case 2:
          return this.$t(`verificationStatus.title.LV3`);
        default:
          return this.$t(`verificationStatus.title.default`);
      }
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('register', [
      'actionGetCurrentStep',
      'actionAccountAuditStatus',
      'actionPoiAuditStatus',
      'actionPoaAuditStatus'
    ]),
    async init() {
      // Avoid remain same step in another tab
      await this.actionGetCurrentStep();
      const formatedCurrentStep = parseInt(this.currStep.toString().split('.')[0]);
      if (formatedCurrentStep <= 1) {
        this.cardData[1].buttonType = 'default';
        if (this.currStep === 1.2) this.cardData[1].states = 'start';
        return;
      }

      const apiActions = [
        formatedCurrentStep > 1 ? this.actionAccountAuditStatus() : Promise.resolve(null),
        this.actionPoiAuditStatus(),
        this.actionPoaAuditStatus()
      ];

      try {
        const apiData = await Promise.all(apiActions);

        if (apiData[0] >= 0) {
          this.setType(1, apiData[0]);
          this.cardData[2].buttonType = 'default';
        }
        if (apiData[1] >= 0) {
          this.setType(2, apiData[1]);
          this.cardData[3].buttonType = 'default';
        }
        if (apiData[2] >= 0) {
          this.setType(3, apiData[2]);
        }
      } catch (error) {
        this.$message({ message: this.$t('responseMsg.500'), type: 'error' });
      }
    },
    setType(step, status) {
      this.cardData[step].buttonType = this.getButtonType(getStatus(status), step);
      this.cardData[step].states = this.getStatusType(getStatus(status), step);
    },
    getButtonType(type, step) {
      switch (type) {
        case statusConfig.completed:
          return 'complete';
        case statusConfig.submitted:
        case statusConfig.reaudit:
          return 'pending';
        case statusConfig.pending:
          return step === 1 ? 'pending' : 'default';
        case statusConfig.rejected:
          return step === 1 ? 'rejected' : 'default';
        default:
          return 'default';
      }
    },
    getStatusType(status, step) {
      switch (status) {
        case statusConfig.completed:
          return 'complete';
        case statusConfig.submitted:
        case statusConfig.reaudit:
          return 'pending';
        case statusConfig.pending:
          return step === 1 ? 'pending' : 'resubmit';
        case statusConfig.rejected:
          return step === 1 ? 'rejected' : 'resubmit';
        default:
          return 'start';
      }
    },
    goToRegister(key) {
      if (!this.cardData[key].buttonType.includes('default')) return;

      let destination = null;
      switch (key) {
        case '1':
          destination =
            this.currStep === 1.2 ? REGISTERROUTER.CREATE_LIVE_ACCOUNT : REGISTERROUTER.PERSONAL_INFORMATION;
          break;
        case '2':
          destination = REGISTERROUTER.IDENTITY_VERIFICATION;
          break;
        case '3':
          destination = REGISTERROUTER.POA_VERIFICATION;
          break;
        default:
          break;
      }

      if (destination) this.$router.push(destination);
    },
    goToDeposit() {
      this.$router.push(DEPOSIT_PATHNAME);
    },
    goToWithdraw() {
      this.$router.push('/withdrawFunds');
    },
    showDepositNow(states, index) {
      if (this.cardData[1].states === 'complete') return ['complete', 'pending'].includes(states) && Number(index) < 3;
      return false;
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/register/registerNew.scss';

.step-card-box {
  display: flex;
  flex-wrap: wrap;
}
.step-card {
  width: 100%;
  flex: 0 0 100%;
  background: $white;
  border-radius: 10px;
  margin: 0 0 20px;
  padding: 18px 24px;
  &:first-of-type {
    background: $white url(~@/assets/images/register/card01.png) no-repeat;
    // @include rtl-sass-value(background-position, 90% center, 10% center);
    @include rtl-sass-value(background-position, 98% center, 0% center);
    background-size: contain;
  }
  &:nth-of-type(2) {
    background: $white url(~@/assets/images/register/card02.png) no-repeat;
    @include rtl-sass-value(background-position, 103% center, -3% center);
    background-size: contain;
  }
  &:nth-of-type(3) {
    background: $white url(~@/assets/images/register/card03.png) no-repeat;
    @include rtl-sass-value(background-position, 103% center, -3% center);
    background-size: contain;
  }
  .sub-title {
    margin-bottom: 14px;
  }
  &-content {
    margin-bottom: 16px;
    font-weight: 300;
  }
  &-link {
    color: $pug-blue;
    font-size: 14px;
    font-weight: 800;
    cursor: pointer;
    img {
      display: inline-block;
      margin-left: 8px;
    }
  }
  &-note {
    color: $pug-red-note;
    font-size: 12px;
    line-height: 16px;
    span {
      color: $pug-blue;
      cursor: pointer;
    }
  }
}
.card-btn {
  margin-bottom: 16px;
  /deep/.el-button {
    font-size: 16px;
    font-weight: 400;
  }
}

@media (min-width: 1024px) {
  .step-card-box {
    flex-wrap: nowrap;
  }

  .step-card {
    width: 380px;
    height: 292px;
    flex: unset;
    margin-top: 0;
    margin-bottom: 20px;
    @include rtl-sass-prop(margin-right, margin-left, 30px);
    @include rtl-sass-prop(margin-left, margin-right, 20px);
    margin-left: 0;
    padding: 32px 36px 24px;
    &:first-of-type,
    &:nth-of-type(2),
    &:nth-of-type(3) {
      background-size: unset;
    }
    &:last-of-type {
      margin: 0 0 20px;
    }
    &-content {
      height: calc(100% - 132px);
      overflow-y: auto;
      overflow-x: hidden;
      margin-bottom: 8px;
    }
  }

  .card-btn {
    margin-bottom: 24px;
  }
}
</style>
